import React from "react";

const PlayerIcon = props => {
  if (props.show === "1") {
    var classes = "hp__video-icon hp__video-icon--";

    if (props.type === "video") {
      classes += "small";
    } else {
      classes += props.type + "-column";
    }

    return (
      <svg
        className={classes}
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="320"
        height="320"
        viewBox="0 0 320 320"
      >
        <circle cx="160" cy="160" r="151" strokeWidth="18" fillOpacity="0" />
        <path
          d="M 130 163 L 130 206 C 130 207.5 131.3 208.3 132.7 207.7 L 232.2 161.2 C 233.6 160.6 233.6 159.3 232.2 158.7 L 132.7 112.2 C 131.3 111.6 130 112.5 130 114 L 130 157 C 130 158.5 130 161.5 130 163 Z"
          rx="15"
          ry="15"
        />
      </svg>
    );
  } else {
    return null;
  }
};

export default PlayerIcon;

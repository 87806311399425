import React from "react";
import { styled } from "../../../stitches.config";
import Link from "../../Link";
import LazyImage from "../../LazyImage";
import Label from "../Label";
//
import Social from "./component/Social";
import PlayerIcon from "./component/PlayerIcon";
import PhotoCount from "./component/PhotoCount";
import Partner from "./component/Partner";
import ReadLaterIcon from "../../ReadLater";

export default class News extends React.Component {
  render() {
    const _renderBlogPost = () => {
      var s = this.props.settings,
        bi = s["background-image"] ? s["background-image"] : "",
        avatar = window.MEDIA_DOMAIN + "/" + bi.replace(".", ",102,101,1,0."),
        classes = "hp__box--column hp__box--column--blog " + this.props.classes;

      return (
        <div className={classes} {...this.props.events}>
          <Link to={s.blog_url} className="hp__box__blog col-xs-12">
            <img alt={s.blog_author} src={avatar} className="hp__blog__photo" />
            <span className="hp__box__label container text-uppercase">
              {s.blog_author}
            </span>
          </Link>

          <div className="hp__box__content--zwykle col-xs-12">
            <Link to={s.link}>
              <h2 className="hp__box__title container col-xs-12 col-sm-12 no-margin-padding">
                {s.title_normal}
              </h2>
            </Link>
          </div>

          <BlogContent>
            <p className="hp__box__blog__text"> {s.blog_text}</p>
            <Social link={s.link} />
          </BlogContent>
        </div>
      );
    };

    const _renderNews = () => {
      var s = this.props.settings,
        bi = s["background-image"] ? s["background-image"] : "",
        mi = bi,
        thumbnailSize = "557,375,1,0",
        mobileThumbnailSize = "168,112,1,0",
        classes = "hp__box--column " + this.props.classes,
        onPicture = (s.on_picture === "1" && this.props.classes === 'col-xs-12'); // na mobile wyświetlamy "on picture" tylko dla zdjęć na całą szerokość

      if (this.props.thumbnailWidth > 0) {
        //eslint-disable-next-line
        var thumbnailWidth = parseInt(this.props.thumbnailWidth);
        thumbnailSize = thumbnailWidth + ",3000,0,0";
        mobileThumbnailSize = "360,500,0,0";
      }

      bi = window.MEDIA_DOMAIN + "/" + bi.replace(".", "," + thumbnailSize + ".");
      mi = window.MEDIA_DOMAIN + "/" + mi.replace(".", "," + mobileThumbnailSize + ".");

      if (s.background) {
        classes += " hp__box--column--" + s.background;
      }

      var blockBg = this.props.blockSettings.background_color,
        labelStyle = {};
      if (blockBg && blockBg.length > 1) {
        labelStyle.backgroundColor = blockBg;
      }

      return (
        <Link to={s.link} className={classes} {...this.props.events}>
          <div className="hp__box__image col-xs-12">
            <ReadLaterIcon
              link={s.link}
              image={bi}
              title={s.title_normal}
              isMovedLeft={this.props.block_type === "one"}
            />
            <LazyImage
              alt={s.title_normal}
              // data-original={bi}
              // data-original-mobile={mi}
              src={mi}
              isBlockOne={this.props.block_type === "one"}
            />
            <Partner partner={s.partner} />
            <PlayerIcon show={s.player} type={this.props.block_type} />
            <PhotoCount count={s.photo_count} type={this.props.block_type} />
            {s.label && s.label.length > 0 && !onPicture ? (
              <Label> {s.label} </Label>
            ) : null}
          </div>
          <div
            className={
              "hp__box__content--zwykle caption col-xs-12" +
              (onPicture ? " on-picture" : "")
            }
          >
            {s.label && s.label.length > 0 && onPicture ? (
              <span
                className="hp__box__label box__one__label--onpicture hp__box__label--alert container"
                style={labelStyle}
              >
                {s.label}
              </span>
            ) : null}
            <Header
              className="hp__box__title"
              hasBackground={s.background === "alert-orange"}
            >
              {" "}
              {s.title_normal}{" "}
            </Header>
            <Social link={s.link} />
          </div>
        </Link>
      );
    };

    return this.props.settings.blog_post === "1"
      ? _renderBlogPost()
      : _renderNews();
  }
}

const BlogContent = styled("div",{
  borderTop: "2px solid",
  borderColor: "$primary",
  padding: 0,
  fontSize: "13px",
  maxHeight: "120px",
  overflow: "hidden",
  position: "relative",
  width: "100%"
});

const Header = styled("h2",{
  margin: "0px",
  backgroundColor: "transparent",
  color: "inherit",
  variants: {
    "hasBackground":{
      true: {
        margin: "0 -2px",
        backgroundColor: "$primary",
        color: "$grayscale0Fixed"
      },
      false: {}
    }
  }
});

News.defaultProps = {
  blockSettings: {},
  classes: "col-xs-6"
};

import React from "react";

const Partner = props => {
  if (props.partner && props.partner.length > 0) {
    return <div className="hp__box__material-partnera">{props.partner}</div>;
  } else {
    return null;
  }
};

export default Partner;

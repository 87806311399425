import { styled } from "../../stitches.config";

const Label = styled("div",{
  backgroundColor: "$primary",
  color: "#fff",
  display: "inline-block",
  fontWeight: "normal",
  fontSize: "11px",
  padding: "2px 6px 1px 6px",
  textAlign: "center",
  width: "auto",
  verticalAlign: "middle",
  whiteSpace: "nowrap",
  position: "absolute",
  bottom: "0",
  left: "0",
  variants: {
    "pile":{
      true: {
        position: "static",
        bottom: "-18px"
      },
      false: {}
    }
  }
});

export const NewsListLabel = styled("span",{
  background: "$primary",
  fontSize: "12px",
  padding: "4px 6px",
  letterSpacing: "0.3px"
});

export default Label;

import React from "react";

const PhotoCount = props => {
  // eslint-disable-next-line
  var count = parseInt(props.count);

  if (count > 0) {
    return (
      <div className="hp__box__layers">
        <img
          alt="Liczba zdjęć w galerii"
          src={window.STATIC_DOMAIN + "/gfx/v2/layers_icon-@2x.png"}
          title=""
          className="hp__layers-icon"
        />
        <h6 className="hp__layers-text">{count}</h6>
      </div>
    );
  } else {
    return null;
  }
};

export default PhotoCount;

import React from "react";

const Social = props => {
  return (
    <p
      className="hp__box__social hp__box__social--alert hidden-xs col-xs-offset-1 col-sm-12 no-margin-padding"
      data-stat-furl={props.link}
    >
      <span className="ss-facebook">&#xF610;</span>
      <span className="hp__box__social__facebook">0</span>
      <span
        className="ss-chat"
        dangerouslySetInnerHTML={{ __html: "&#x1F4AC;" }}
      />
      <span className="hp__box__social__chat">0</span>
    </p>
  );
};

export default Social;

import React from "react";
import { styled, keyframes } from "../stitches.config";

export default class LazyImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoaded: false
    };
  }
  handleImageLoad = () => {
    this.setState({ imageLoaded: true });
  };

  render() {
    return (
      <ImageWrapper className="lazy-image__container">
        <Spinner imageLoaded={this.state.imageLoaded}>
          <div className="rect1" />
          <div className="rect2" />
          <div className="rect3" />
          <div className="rect4" />
          <div className="rect5" />
        </Spinner>
        {this.props.children}
        <Image
          {...this.props}
          onLoad={this.handleImageLoad}
          imageLoaded={this.state.imageLoaded}
        />
      </ImageWrapper>
    );
  }
}

const ImageWrapper = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  background: "#dadada",
  alignItems: "center",
  justifyContent: "center"
});

const scale = keyframes({
  "0%, 40%, 100%": {
    transform: "scaleY(0.4)"
  },
  "20%": {
    transform: "scaleY(1)"
  }
});

const Spinner = styled("div", {
  height: "60px",
  width: "60px",
  transition: "all 0.3s ease-in-out 0s",
  display: "block",
  position: "absolute",
  left: "calc(50% - 30px)",
  bottom: "calc(50% - 30px)",
  right: "calc(50% - 30px)",
  top: "calc(50% - 30px)",
  div: {
    height: "100%",
    width: "7px",
    margin: "0 3px 0 0",
    backgroundColor: "#fff",
    display: "inline-block",
    animation: `${scale} 1.2s infinite ease-in-out`
  },
  ".rect2": { animationDelay: "-1.1s" },
  ".rect3": { animationDelay: "-1.0s" },
  ".rect4": { animationDelay: "-0.9s" },
  ".rect5": { animationDelay: "-0.8s" },
  variants: {
    "imageLoaded": {
      true: {
        display: "none"
      },
      false: {}
    }
  }
});

const Image = styled("img", {
  width: "100%",
  float: "left",
  height: "0",
  varinats: {
    "imageLoaded": {
      true: {
        height: "auto",
        paddingTop: "0 !important"
      },
      false: {}
    },
    "isBlockOne": {
      true: {
        paddingTop: "33%"
      },
      false: {
        paddingTop: "65%"
      }
    }
  },
});
